import React from 'react';
import { PageProps } from 'gatsby';

import Layout from 'components/templates/Layout';
import SEO from 'components/templates/SEO';
import { PostExcerpt } from 'types/post';
import PostExcerptCard from 'components/molecules/PostExcerptCard';
import PaginationFooter from 'components/molecules/PaginationFooter';

interface IndexProps extends PageProps {
  pageContext: {
    posts: {
      node: PostExcerpt;
    }[];
    maxPage: number;
    currentPage: number;
    baseUrl: string;
  };
}

const IndexPage = ({ pageContext }: IndexProps) => {
  const { posts, maxPage, currentPage, baseUrl } = pageContext;
  return (
    <Layout>
      <SEO title="踩坑日記" description="一個因興趣使然的軟體工程師所踩過的一堆坑" />
      {posts.map(({ node }) => (
        <div key={node.frontmatter.slug || node.frontmatter.title}>
          <PostExcerptCard post={node} />
        </div>
      ))}
      <PaginationFooter
        baseUrl={baseUrl}
        currentPage={currentPage}
        maxPage={maxPage}
        firstPageOverride="/"
      />
      <div className="mt-8" />
    </Layout>
  );
};

export default IndexPage;
